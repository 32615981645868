//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import { getDateformat } from '@/util/getDate';
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState({
      orderInfo: state => state.order.orderInfo.info,
    }),
  },
  watch: {
    orderInfo(val) {
      let printCon = localStorage.getItem('printCon') || ['商品图片', '商品单价', '商品总价', '优惠金额', '快递运费', '商家备注'];
      if (typeof printCon == 'string') {
        printCon = JSON.parse(printCon);
      }
      for (let i in printCon) {
        if (printCon[i] == '商品图片') {
          this.orderInfo.is_showGoods = !0;
        }
        if (printCon[i] == '商品单价') {
          this.orderInfo.is_goodsPrice = !0;
        }
        if (printCon[i] == '商品总价') {
          this.orderInfo.is_totalPrice = !0;
        }
        if (printCon[i] == '优惠金额') {
          this.orderInfo.is_discount = !0;
        }
        if (printCon[i] == '快递运费') {
          this.orderInfo.is_freight = !0;
        }
        if (printCon[i] == '商家备注') {
          this.orderInfo.is_message = !0;
        }
      }
    },
  },
  created() {
    let id = this.$route.params.id;
    this.$store.dispatch('order/getOrderInfo', { id: id });
  },
  methods: {
    getDateformat(val) {
      return getDateformat(val);
    },
  },
};
